<template>
  <div>
    <el-form
      ref="form"
      :model="formData"
      label-width="250px"
      label-position="left"
    >
      <el-form-item label="开单后不显示‘ 选择员工 ’页面">
        <el-switch
          v-model="formData.switchValue"
          active-color="#13ce66"
          inactive-color="#dddddd"
        ></el-switch>
      </el-form-item>
	  
      <el-form-item label="同步轮牌员工">
        <el-radio-group v-model="formData.PadBillingEmpSrc">
          <el-radio
            v-for="(item, index) in [
              { Name: '否', Value: 0 },
              { Name: '是', Value: 1 },
            ]"
            :key="item.index"
            :label="item.Value"
          >
            {{ item.Name }}
          </el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit" size="small"
          >确 定</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from "@/api/setting.js";
export default {
  name: "basis",
  data() {
    return {
      formData: {
        switchValue: false,
      },
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {

    async queryData() {
      let { data } = await api.padBasic("init_data");
      this.formData = {
        ...data,
        switchValue: data.PadQuickBilling == 0 ? false : true,
      };
    },

    async onSubmit() {
      try {
        let submitData = {
          PadBillingEmpSrc: this.formData.PadBillingEmpSrc,
          PadQuickBilling: this.formData.switchValue ? 1 : 0,
        };
        let data = await api.pad_basic(submitData, "save_data");
        this.$message({ message: "设置成功", type: "success" });
      } catch (error) {
        this.$message.error("设置失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-form {
  width: 720px;
  margin: 0 auto;
}
</style>
