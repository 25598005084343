import {
	request
} from "../utils/axios";

export default {
	// 设置 ==> app 设置 ==> 二维码展示
	padShow(params = {}, subact) {
		return request('get', `/setting/pad_show?subact=${subact}`, params);
	},

	pad_show(params = {}, subact) {
		return request('post', `/setting/pad_show?subact=${subact}`, params);
	},

	// 设置 ==> app 设置 ==> 基础设置
	padBasic(subact) {
		return request('get', `/setting/pad_basic?subact=${subact}`);
	},

	pad_basic(params = {}, subact) {
		return request('post', `/setting/pad_basic?subact=${subact}`, params);
	},

	// 设置 ==> 小程序设置 ==> 基础设置 (小程序设置-获取信息)
	wxmpBasic(params = {}, subact) {
		return request('post', `/setting/wxmp_basic?subact=${subact}`, params);
	},

	// 设置 ==> 小程序设置 ==> WIFI设置 (小程序wifi-获取信息)
	wxmpWifi(subact) {
		return request('get', `/setting/wxmp_wifi?subact=${subact}`);
	},

	wxmp_wifi(params = {}, subact) {
		return request('post', `/setting/wxmp_wifi?subact=${subact}`, params);
	},

	// 设置 ==> 小程序设置 ==> 门店二维码设置
	storeQrcode(params = {}, subact) {
		return request('get', `/setting/store_qrcode?subact=${subact}`, params);
	},

	// 设置 ==> 折扣比例
	getParadisc(params = {}) {
		return request('get', `/Para/get_paradisc`, params);
	},

	addParadisc(params = {}) {
		return request('post', `/Para/add_paradisc`, params);
	},

	updateParadisc(params = {}) {
		return request('post', `/Para/update_paradisc`, params);
	},

	deleteParadisc(params = {}) {
		return request('get', `/Para/delete_paradisc`, params);
	},

	synParadisc(auth_key, auth_code, params = {}) {
		return request('post', `/Para/syn_paradisc?auth_key=${auth_key}&auth_code=${auth_code}`, params);
	},

	// 设置 ==> 单位设置
	getParauntislist(params = {}) {
		return request('get', `/Para/get_parauntislist`, params);
	},

	addParauntis(params = {}) {
		return request('post', `/Para/add_parauntis`, params);
	},

	updateParauntis(params = {}) {
		return request('post', `/Para/update_parauntis`, params);
	},

	deleteParauntis(params = {}) {
		return request('get', `/Para/delete_parauntis`, params);
	},

	// 设置 ==> 水单号设置
	getBillNoList(params = {}) {
		return request('get', `/para/get_bill_no_list`, params);
	},

	getBillNoBatchList(params = {}) {
		return request('get', `/para/get_bill_no_batch_list`, params);
	},


	deleteBillNo(params = {}) {
		return request('get', `/para/delete_bill_no`, params);
	},

	addBillNo(params = {}) {
		return request('post', `/para/add_bill_no`, params);
	},

	deleteBillNoList(params = {}) {
		return request('get', `/para/delete_bill_no_list_guid`, params);
	},

	releaseBillNo(params = {}) {
		return request('get', `para/release_bill_no`, params);
	},

	deleteBillNoRange(params = {}) {
		return request('get', `/para/delete_bill_no_range`, params);
	},

	// 设置 ==> 评价设置
	initEvaluate(params = {}) {
		return request('get', `/setting/evaluate?subact=init_data`, params);
	},

	saveEvaluate(params = {}) {
		return request('post', `/setting/evaluate?subact=save_data`, params);
	},

	// 设置 ==> 基础设置 ==> 基础参数
	initPreference(params = {}) {
	    return request('get', `/setting/preference?subact=init_data`, params);
	},

	savePreference(params = {}) {
	    return request('post', `/setting/preference?subact=save_data`, params);
	},

	// 设置 ==> 基础设置 ==> 基础参数
	initRound(params = {}) {
	    return request('get', `/setting/round?subact=init_data`, params);
	},

	saveRound(params = {}) {
	    return request('post', `/setting/round?subact=save_data`, params);
	},

}